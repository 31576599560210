exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-1-spaces-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/1-getting-started/1-spaces.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-1-spaces-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-2-installations-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/1-getting-started/2-installations.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-2-installations-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-3-user-management-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/1-getting-started/3-user-management.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-3-user-management-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-4-content-management-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/1-getting-started/4-content-management.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-4-content-management-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-5-alerts-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/1-getting-started/5-alerts.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-5-alerts-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-6-devices-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/1-getting-started/6-devices.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-6-devices-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-7-networking-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/1-getting-started/7-networking.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-7-networking-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-marketplace-apps-0-endless-aisle-release-notes-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/2-marketplace-apps/0-endless-aisle-release-notes.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-marketplace-apps-0-endless-aisle-release-notes-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-marketplace-apps-0-queue-management-release-notes-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/2-marketplace-apps/0-queue-management-release-notes.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-marketplace-apps-0-queue-management-release-notes-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-marketplace-apps-1-queue-management-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/2-marketplace-apps/1-queue-management.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-marketplace-apps-1-queue-management-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-marketplace-apps-2-trigger-playlist-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/2-marketplace-apps/2-trigger-playlist.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-marketplace-apps-2-trigger-playlist-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-marketplace-apps-3-endless-aisle-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/2-marketplace-apps/3-endless-aisle.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-marketplace-apps-3-endless-aisle-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-marketplace-apps-4-content-player-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/2-marketplace-apps/4-content-player.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-marketplace-apps-4-content-player-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-3-whats-new-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/3-whats-new.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-3-whats-new-mdx" */)
}

